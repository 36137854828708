import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

const Projects = () => {
	return (
		<article>
			<Header />

			<Pattern title="EU Youth Cinema: Green Deal">

			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/10E0I0mLbPikFxrW1b6ZEeghBVXPfYxYw/view?usp=drive_link" target="_blanck">						
					Інформаційний банер програми EU Youth Cinema: Green Deal
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://docs.google.com/document/d/1sE0iamvrxJUpSYazKBFY8fuj0oyfX6cR/edit?usp=drive_link&ouid=115639848947452629089&rtpof=true&sd=true" target="_blanck">						
					Оголошення-запрошення EU Youth Cinema: Green Deal
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://youth-cinema.eu/ua/" target="_blanck">						
					Реєстрація на програму Green Deal
					</a>
				</h4>
			</Pattern>

			<Partners />

			<Footer />
		</article>
	)
};

export default Projects;
