import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Certificats = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='сертифікати'>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/11Us85YpDKNUT57h_tRa4rZl0xvjsX_v2/view?usp=drive_link" target="_blanck">
					Сертифікат ОПП архітектура та будівництво
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1xfZhvI74_WS-UrpFFd1mmu6Q_UPWeYxg/view?usp=drive_link" target="_blanck">
					Сертифікат ОПП Обслуговування та ремонт автомобілів та двигунів
					</a>
				</h4>
				<br />
				<h4>				
					<a href="docs/certificates-of-certification.pdf" target="_blanck">
						Свідоцтво про атестацію
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="docs/appendix-attestation-certificate-1.pdf" target="_blanck">
						Додаток до свідоцтва про атестацію 1
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="docs/appendix-attestation-certificate-2.pdf" target="_blanck">
						Додаток до свідоцтва про атестацію 2
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Certificats;
